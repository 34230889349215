// @ts-nocheck
// TODO: типизировать после переделки модулей стора

import Vuex, { Commit } from "vuex";
import NewAxiosWrapper from "@/plugins/NewAxiosWrapper";
import uiModule from "./ui";
import dataModule from "./data";
import authModule from "./auth";
import userModule from './user';
import { useLastPage } from "@/shared/composables/useLastPage";
import type { LoginSuccessResponse, RootState } from "@/store/types";

const isProdMode = process.env.VUE_APP_PROD_MODE;

export default new Vuex.Store<RootState>({
  state: {
    isAuthenticated: false,
    token: "",
    sizes: {vh: 0, vw: 0}
  },
  mutations: {
    //@ts-ignore
    "SET_DATA_BY_KEY": (state, { key, value }) => state[key] = value
  },
  actions: {
    authUser: async (
      { commit }: { commit: Commit },
      {email, password, remember, callBack, language}:
      { email: string, password: string, remember: boolean, callBack: () => {}, language: string }
    ) => {

      const mtd = isProdMode ? 'user_login' : 'signin';

      const data = {
        email,
        password,
        mtd,
        remember_me: remember? 1 : 0,
        language,
      }
      const resp = await NewAxiosWrapper.request<LoginSuccessResponse>(data);

      const tokenKey = isProdMode ? 'access_token' : 'token';

      if(resp && resp[tokenKey]) {
        localStorage.setItem('token', resp[tokenKey]!);
        commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: true});
        callBack();
      } else {
        return resp
      }
   
    },
    logOutUser: async ({ commit }: { commit: Commit }, callBack: () => {}) => { 
      const mtd = isProdMode ? 'logout' : 'signout';

      await NewAxiosWrapper.request({ mtd });

      localStorage.removeItem('token');
      useLastPage().removeLastPage();
      

      commit("SET_DATA_BY_KEY", {key: 'isAuthenticated', value: false})
      commit("ui/RESET")
      commit("data/RESET")

      callBack();
    }
  },
  modules: {
    auth: authModule,
    ui: uiModule,
    data: dataModule,
    user: userModule,
  },
  getters: {},
});
