export enum ContentType {
  INTEGER = 1,
  REAL_NUMBER = 2,
  SIMPLE_TEXT = 3,
  HYPERTEXT = 4,
  HYPERLINK = 5,
  EMAIL = 6,
  LINK_TO_IMAGE = 7,
  LINK_TO_VIDEO_FILE = 8,
  LINK_TO_AUDIO_FILE = 9,
  DATE = 10,
  TIME_ZONE = 11,
  FINANCIAL = 12,
  PHONE_NUMBER = 13,
  BANK_CARD = 14,
  LANGUAGE = 15,
  COUNTRY = 16,
  POSTAL_ADDRESS = 17,
};

export const Scenario = {
  TO_WATCH_LIST: 'toWatchlist',
  TO_IGNORE: 'toIgnore',
  TO_DEFAULT: 'toDefault',
  TO_STUDIED: 'toStudied',
} as const;

export const playgroundList = [
  {
    playground_id: 3,
    title: "lichess.org",
  },
  {
    playground_id: 4,
    title: "chess.com",
  },
] as const;

export type ScenarioType = typeof Scenario[keyof typeof Scenario];

export type Playground = typeof playgroundList[number];

export const PreanalysisLabels = Object.freeze({
  STUDY_PLAN: 21,
  FAVORITES: 22,
  HIDDEN: 23,
});