import { HeroNickname, UserState } from '@/store/user/types';

export default {
  getCurrentHeroNicknames(state: UserState): (hero_id: number) => HeroNickname[] {
    return (hero_id: number): HeroNickname[] => {
      const heroNicknames = state.heroNicknames;
      if (!heroNicknames.length) {
        return [];
      }
      return heroNicknames
        ?.filter((nick) => nick.hero_id === hero_id)
        .filter((nick) => nick.shown === 1);
    }
  },
}