import actions from './actions';
import state from './state';
import mutations from './mutations';
import getters from './getters';
import { UserState } from './types';

export default {
  state: (): UserState => state,
  actions,
  mutations,
  getters,
  namespaced: true
}
