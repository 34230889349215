import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-2 semibold-14 app-bar-language__desktop" }

import { computed, ref, watch, onMounted } from 'vue';
import { useUIStore } from './shared/composables/useUIStore';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import CustomIcon from '@/assets/CustomIcon.vue';
import { useRouteQueryParams } from '@/router/useRouteQueryParams';

interface LocaleItem {
  title: string;
  id: string;
  name: string;
}
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

;

const localeItems: LocaleItem[] = [
  { title: 'en', id: '1', name: 'English' },
  { title: 'ru', id: '4', name: 'Русский' },
];

const currentLocale = ref<LocaleItem>();

const store = useStore();
const userPreferences = computed(() => store.state.ui.userPreferences);
const isAuthenticated = computed(() => store.state.isAuthenticated);

const { uiDispatch, uiCommit } = useUIStore();
const { synchronizeQueryParams } = useRouteQueryParams();
synchronizeQueryParams();

const token = localStorage.getItem('token');

  if (token) {
    store.commit('SET_DATA_BY_KEY', {
      key: 'isAuthenticated',
      value: true,
    });
  }

const { locale } = useI18n();

const selectLanguage = (item: LocaleItem): void => {
  currentLocale.value = item;
  locale.value = item.title;
  if (!isAuthenticated.value) {
      uiCommit('SET_DATA_BY_KEY', {
        key: 'userPreferences',
        value: { lang_interface: Number(item.id) },
      });
      localStorage.setItem('savedLocale', JSON.stringify(item));
    } else {
      localStorage.setItem('savedLocale', JSON.stringify(item));
      uiDispatch('setUserPreferences', {
        ...userPreferences.value,
        lang_interface: Number(item.id),
      });
    }
};

const savedLocale = localStorage.getItem('savedLocale');

if (savedLocale) {
  try {
    const locale = JSON.parse(savedLocale);
    selectLanguage(locale);
  } catch (error) {
    console.log(error);
  }
} else {
  currentLocale.value = localeItems[0];
}

watch(userPreferences, (val) => {
  if (val && val.lang_interface !== undefined) {
    const targ = localeItems.find(
      (i) => Number(i.id) === val.lang_interface,
    );
    if (targ) {
      currentLocale.value = targ;
      locale.value = targ.title;
      localStorage.setItem('savedLocale', JSON.stringify(targ));
    }
  }
},
  { immediate: true },
);

const setScreenSizes = (): void => {
  store.commit('SET_DATA_BY_KEY', {
        key: 'sizes',
        value: {
        vh:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
        vw:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      },
  });
};

onMounted(() => {
  setScreenSizes();
  window.addEventListener('resize', setScreenSizes);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_portal = _resolveComponent("portal")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "bg-darkGrey" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_portal, { to: "app-bar-portal-language" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_menu, { class: "regular-14" }, {
            activator: _withCtx(({ props }) => [
              _createElementVNode("div", _mergeProps({ class: "app-bar-language-button d-flex align-center pointer" }, props), [
                _createElementVNode("div", _hoisted_1, _toDisplayString(currentLocale.value?.name), 1),
                _cache[0] || (_cache[0] = _createElementVNode("svg", {
                  class: "app-bar-language__desktop",
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M5.48021 6.81331C5.57396 6.71968 5.70104 6.66709 5.83354 6.66709C5.96604 6.66709 6.09313 6.71968 6.18688 6.81331L8.00021 8.62665L9.81355 6.81331C9.85932 6.76419 9.91452 6.72479 9.97585 6.69746C10.0372 6.67013 10.1034 6.65544 10.1705 6.65425C10.2377 6.65307 10.3044 6.66542 10.3666 6.69057C10.4289 6.71571 10.4854 6.75314 10.5329 6.80062C10.5804 6.8481 10.6178 6.90466 10.643 6.96692C10.6681 7.02917 10.6805 7.09586 10.6793 7.163C10.6781 7.23013 10.6634 7.29634 10.6361 7.35767C10.6087 7.41901 10.5693 7.47421 10.5202 7.51998L8.35354 9.68665C8.25979 9.78028 8.13271 9.83287 8.00021 9.83287C7.86771 9.83287 7.74063 9.78028 7.64688 9.68665L5.48021 7.51998C5.38658 7.42623 5.33398 7.29915 5.33398 7.16665C5.33398 7.03415 5.38658 6.90706 5.48021 6.81331Z",
                    fill: "#9A9A9A"
                  })
                ], -1)),
                _createVNode(CustomIcon, {
                  class: "app-bar-language__mobile",
                  id: "world"
                })
              ], 16)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list, {
                density: "compact",
                class: "regular-14"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(localeItems, (item) => {
                    return _createVNode(_component_v_list_item, {
                      key: item.id,
                      onClick: ($event: any) => (selectLanguage(item))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  }), 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})