import AxiosWrapper from '@/plugins/NewAxiosWrapper';
import type { LoadGamesRequest, NicknamesReassignRequest, NicknamesToggleRequest, HeroesAddRequest, HeroesChangeInfoRequest, HeroesDeleteRequest, HeroesRelocateRequest, HeroesRenameRequest, NicknamesAddRequest, NIcknamesDeleteRequest, NicknamesUnzipRequest, SuccessMessage } from '@/store/types';
import type { Commit, Dispatch } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { UserInfo } from './types';
import { Methods } from '@/store/enums';

export default {
  async getUserGamesInfo({ commit }: { commit: Commit }) {
    const result = await AxiosWrapper.request<{}, UserInfo>({ mtd: Methods.MAIN_PAGE });
    if (result) {
      const { heroes, nicknames } = result;
      commit('SET_DATA_BY_KEY', {
        key: 'heroesList',
        value: heroes,
      });
      commit('SET_DATA_BY_KEY', {
        key: 'heroNicknames',
        value: nicknames,
      });
    }
  },
  async renameHero(
    { dispatch }: { dispatch: Dispatch },
    { heroNameModel, heroId }: { heroId: string, heroNameModel: any}
  ) {
    const data = {
      mtd: Methods.HEROES_RENAME,
      name: heroNameModel,
      heroid: heroId,
    };

    const resp = await AxiosWrapper.request<HeroesRenameRequest, SuccessMessage>(data);

    if (resp) {
      dispatch('getHeroesList');
    }
  },
  async updateHeroInfo(
    { dispatch }: { dispatch: Dispatch },
    { heroId, heroinfo }: { heroId: string, heroinfo: any },
  ) {

    const data = {
      mtd: Methods.HEROES_CHANGE_INFO,
      heroid: Number(heroId),
      newinfo: heroinfo,
    };

    const resp = await AxiosWrapper.request<HeroesChangeInfoRequest, any>(data);
    if (resp) {
      dispatch('getHeroesList');
    }
  },
  async addHero(
    { dispatch }: { dispatch: Dispatch },
    { heroNameModel, heroinfo }: { heroNameModel: string, heroinfo: any },
  ) {
    const data = {
      mtd: Methods.HEROES_ADD,
      name: heroNameModel,
      heroinfo,
    };
    const resp = await AxiosWrapper.request<HeroesAddRequest, any>(data);
    if (resp && !resp.errorMessage) {
      dispatch('getHeroesList');
      return;
    } else if (resp.errorMessage) {
      return { errorMessage: resp.errorMessage };
    }
  },
  async deleteHero(
    { dispatch }: { dispatch: Dispatch },
    { heroId }: { heroId: string },
  ) {
    const data = {
      mtd: Methods.HEROES_DELETE,
      heroid: heroId,
    };

    const resp = await AxiosWrapper.request<HeroesDeleteRequest, any>(data);
    if (resp) {
      dispatch('getHeroesList');
    }
  },
  async relocateHero(
    { dispatch }: { dispatch: Dispatch },
    { heroId, afterId }: { heroId: string, afterId: string },
  ) {
    const data = {
      mtd: Methods.HEROES_RELOCATE,
      heroid: heroId,
      after: afterId,
    };
    const resp = await AxiosWrapper.request<HeroesRelocateRequest, any>(data);
    if (resp) {
      dispatch('getHeroesList');
    }
  },
  async addHeroNickName(
    { dispatch }: { dispatch: Dispatch },
    { nickNameModel, siteModel, heroId }: { nickNameModel: string, siteModel: any, heroId: string }
  ) {
    const data = {
      mtd: Methods.NICKNAMES_ADD,
      nickname: nickNameModel,
      pgid: Number(siteModel),
      heroid: Number(heroId),
    };

    const resp = await AxiosWrapper.request<NicknamesAddRequest, any>(data);
    if (resp && !resp.errorMessage) {
      dispatch('getUserGamesInfo');
      return;
    } else if (resp.errorMessage) {
      return { errorMessage: resp.errorMessage };
    }
  },
  async deleteNickName(
    { dispatch }: { dispatch: Dispatch },
    { nickname_id }: { nickname_id: string }
  ) {
    const data = {
      mtd: Methods.NICKNAMES_DELETE,
      nickid: Number(nickname_id),
    };

    const resp = await AxiosWrapper.request<NIcknamesDeleteRequest, any>(data);

    if (resp) {
      dispatch('getUserGamesInfo');
    }
  },
  async unzipNickname(
    { dispatch }: { dispatch: Dispatch },
    { nickname_id }: { nickname_id: string }
  ) {

    const data = { mtd: Methods.NICKNAMES_UNZIP, nickid: Number(nickname_id) };

    const resp = await AxiosWrapper.request<NicknamesUnzipRequest, any>(data);
    if (resp) {
      dispatch('getUserGamesInfo');
    }
  },
  async toggleNickname(
    { commit, dispatch, state }: { dispatch: Dispatch, state: any, commit: Commit },
    { mtd, nickname_id, heroid }: { mtd: Methods, nickname_id: string, heroid: string },
  ) {
    const data = { mtd, nickid: Number(nickname_id) };

    const resp = await AxiosWrapper.request<NicknamesToggleRequest, SuccessMessage>(data);

    if (resp) {
      dispatch('getUserGamesInfo');
    }
  },
  async reassignNickname(
    { dispatch, state, commit }: { dispatch: Dispatch, state: any, commit: Commit },
    params: any
  ) {
    const data = {
      nickid: Number(params.nickid),
      heroid: Number(params.hid),
      mtd: Methods.NICKNAMES_REASSIGN,
    };
    const resp = await AxiosWrapper.request<NicknamesReassignRequest, SuccessMessage>(data);
    if (resp) {
      const clone = cloneDeep(state.heroNicknames);

      const heroids = Object.keys(clone);

      for (const key of heroids) {
        clone[key] = clone[key].filter(
          (nick: any) => params.nickid !== nick.nickname_id,
        );
      }

      commit('SET_DATA_BY_KEY', { key: 'heroNicknames', value: clone });

      await dispatch('getUserGamesInfo', params.hid);
    }
  },
  async uploadGames({ dispatch }: { dispatch: Dispatch }, { requestParams, heroId }: { requestParams: any, heroId: string}) {

    const prepareTimeControls = (arr: any[]) => {
      try {
        const merged = arr.join(',');
        return merged.split(',').map((i) => Number(i));
      } catch (e) {
        return arr;
      }
    };
    const data = {
      mtd: Methods.LOAD_GAMES,
      nickid: Number(requestParams.nickid),
      timecontrols: prepareTimeControls(requestParams.timecontrols),
      datefrom: requestParams.date_from,
      dateto: requestParams.date_to,
      pgn: '',
    };
    const resp = await AxiosWrapper.request<LoadGamesRequest, any>(data);
    if (resp) {
      dispatch('getUsergameSummary', heroId);
    }
  },
};