const deepFind = ({ array, key = 'id', value, cb = null }) =>
array.reduce((accum, current) => {
  if (current[key] === value) {
    if(!cb) {
      accum = current;
    } else {
      if(cb(current)) {
        accum = current;
      }
    }
  } 
  if (current.children && current.children.length > 0) {
    const childrenSearchResult = deepFind({
      key,
      value,
      array: current.children,
      cb
    });
    if (childrenSearchResult) accum = childrenSearchResult;
  }
  return accum;
}, null);

export default deepFind