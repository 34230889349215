import { filterNonEmptyKeys } from "@/helpers/filterNonEmptyKeys";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

interface RouteQueryParams {
  heroid?: string;
  categories?: string;
  posid?: string;
  herowhite?: string;
}

export const routeQueryParams = ref<RouteQueryParams>({
  heroid: '0',
  categories: '',
  posid: '',
  herowhite: '',
});

export const useRouteQueryParams = () => {
  const route = useRoute();
  const router = useRouter();
  const synchronizeQueryParams = () => {
    const urlObj = new URL(window.location.toString());
    const params = new URLSearchParams(urlObj.search);
    const queryParams: Record<string, string> = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });
    routeQueryParams.value = queryParams;
  };

  const setQueryParams = (params: Partial<RouteQueryParams>) => {
    const queryParams = {
      ...routeQueryParams.value,
      ...params,
    };
    routeQueryParams.value = filterNonEmptyKeys(queryParams);
    router.replace({
      ...route,
      query: routeQueryParams.value,
    });
  };

  const getQueryParamByKey = (param: keyof RouteQueryParams): RouteQueryParams[keyof RouteQueryParams] => {
    return routeQueryParams.value[param];
  };

  return {
    setQueryParams,
    synchronizeQueryParams,
    getQueryParamByKey,
  };
};