import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "semibold-14"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { style: {"width":"32px"} }
const _hoisted_4 = ["value"]

interface InputProps {
  label?: string;
  step?: number;
  value: string | number;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ScNumberInput',
  props: {
    label: {},
    step: {},
    value: {}
  },
  emits: ['onInput'],
  setup(__props: any, { emit: __emit }) {


const props = __props;

const emit = __emit;
const onInput = (value: string) => {
  if (Number.isNaN(Number(value))) return;
  emit('onInput', value);
};
const handleIconClick = (direction: string) => {
  const currentValue = Number(props.value) || 0;
  const newValue =
    currentValue +
    (props.step ? props.step : 1) * (direction === 'decr' ? -1 : 1);
  if (Number.isNaN(Number(newValue))) return;
  emit('onInput', newValue);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", {
        width: "28",
        height: "28",
        viewBox: "0 0 28 28",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleIconClick('decr'))),
        class: "sc-input-button"
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("path", {
          d: "M19.3327 14H8.66602",
          stroke: "#9A9A9A",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, -1)
      ]))),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          value: _ctx.value,
          onChange: _cache[1] || (_cache[1] = e => onInput(e.target.value)),
          type: "text",
          class: "regular-14 sc-input"
        }, null, 40, _hoisted_4)
      ]),
      (_openBlock(), _createElementBlock("svg", {
        width: "28",
        height: "28",
        viewBox: "0 0 28 28",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (handleIconClick('incr'))),
        class: "sc-input-button"
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("path", {
          d: "M13.9993 8.66666V19.3333M19.3327 14L8.66602 14",
          stroke: "#9A9A9A",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, -1)
      ])))
    ])
  ]))
}
}

})