import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

import { ScTableProps, TableRow } from '@/components/Common/ScTable/ScTable.types';
// export interface TableHeader {
//   value: string;
//   title: string;
// }
// export type TableRow = Record<string, unknown>;

// export interface ScTableProps {
//   rows: TableRow[];
//   headers: TableHeader[];
//   height: string | number;
//   fixedHeader: boolean;
//   clickable?: boolean;
// }

export default /*@__PURE__*/_defineComponent({
  __name: 'ScTable',
  props: {
    rows: {},
    headers: {},
    height: {},
    fixedHeader: { type: Boolean },
    clickable: { type: Boolean }
  },
  emits: ['clickOnRow'],
  setup(__props: any, { emit: __emit }) {


//пока перенёс стили в компонент, т.к. npm run serve внезапно стало ругаться
const props = __props;

const emit = __emit;

const onClick = (row: TableRow) => {
  if (!props.clickable) {
    return;
  }
  emit('clickOnRow', row);
};

return (_ctx: any,_cache: any) => {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createBlock(_component_v_table, {
    class: "sc-table",
    "fixed-header": _ctx.fixedHeader,
    height: _ctx.height
  }, {
    default: _withCtx(() => [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "text-left semibold-14",
              key: index,
              style: {"color":"#F4F4F4"}
            }, _toDisplayString(header.title), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            class: _normalizeClass({ 'sc-table__row--selected': row.selected }),
            key: index,
            onClick: ($event: any) => (onClick(row))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (cell, index) => {
              return (_openBlock(), _createElementBlock("td", {
                class: "semibold-14",
                key: index
              }, _toDisplayString(row[_ctx.headers[index].value]), 1))
            }), 128))
          ], 10, _hoisted_1))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["fixed-header", "height"]))
}
}

})